import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'
import MakeoverProducts from '../components/MakeoverDays/MakeoverProducts'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default ({ data: { strapi: { makeoverDaysPage } }, location }) => {
	
	return (
		<>
			<SEO title="Makeover Days" description={ makeoverDaysPage.seoDescription } pathname={ location.pathname } />

			{ makeoverDaysPage && makeoverDaysPage.content &&
				<DynamicZone components={ makeoverDaysPage.content } />
			}
			<MakeoverProducts />
		</>
	)
}

export const query = graphql`
	query makeoverDaysPage {
		strapi {
			makeoverDaysPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentGeneralHeroBanner {
						id
						heroDescription
						heroTitle
						imageFocus
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000, maxHeight: 500) {
										...GatsbyImageSharpFluid_noBase64
							 		}
								}
							}
						}
					}
					... on StrapiQuery_ComponentMakeoverTabs {
						id
						componentHeading
						tabItem {
							id
							contentDescription
							contentTitle
							tabName
							contentImage {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 1000) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentMakeoverImageBannerSplit {
						id
						imageBannerSplitTitle
						imageBannerSplitDescription
						imageBannerSplitAlignment
						imageBannerSplitLinkText
						imageBannerSplitLinkUrl
						imageBannerSplitImage {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750, maxHeight: 750) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentGeneralGeneralIllustrationBanner {
						id
						generalIllustrationDescription
						generalIllustrationImage
						generalIllustrationLinkText
						generalIllustrationLinkUrl
						generalIllustrationTitle
					}
				}
			}
		}
	}
`
