import React, { useContext } from 'react'
import moment from 'moment'

import { useQuery, gql } from '@apollo/client';

import { CartContext } from '../../context/CartContext'

export default () => {

	const { addToCart } = useContext( CartContext );

	const limit = 25
	const sort = "Date:DESC"

	const productQuery = gql`
		query getMakeoverDays ( $limit: Int, $sort: String ) {
			makeoverDays ( limit: $limit, sort: $sort ) {
				Date
				spaceAvailable
				price
				id
			}
		}
	`;

	let { loading, data } = useQuery( productQuery,
		{
			variables: {
				limit: limit,
				sort: sort
			},
			fetchPolicy: "cache-and-network"
		}
	);

	if ( loading ) return <p> loading </p>

	if ( data ) {
		data = JSON.parse( JSON.stringify( data ) )

		data.makeoverDays.sort( (a, b) => {
			return new Date( a.Date ) - new Date( b.Date )
		} )

		// If the makeover day has already been, remove from list
		for ( let i = data.makeoverDays.length - 1; i >= 0; i-- ) {
			const todaysDate = new Date()
			const daysDate = new Date( data.makeoverDays[ i ].Date )

			if ( daysDate.valueOf() <= todaysDate.valueOf() ) {
				data.makeoverDays.splice( i, 1 )
			}
		}
	}

	return (
		<section id="book-makeover-day" className="makeover-products wrapper">
			<div className="makeover-products__heading-container">
				<h1 className= "makeover-products__heading">Book a Makeover Day</h1>
			</div>
			<div className="makeover-products__products-container">
				{ data.makeoverDays.map( day => {
					return (
						<div key={ day.id } className ="makeover-products__row">
							<div className="makeover-products__column" >
								{ moment(day.Date).format("Do MMM YYYY") }
							</div>
							<div className="makeover-products__column makeover-products__column--alt">
								{ day.spaceAvailable } { day.spaceAvailable === 1 ? 'space' : 'spaces' } left
							</div>
							<div className="makeover-products__column makeover-products__column--alt">
								£{ day.price }
							</div>
							<div className="makeover-products__column makeover-products__column--button">
								<button
									className={`button button--no-margin makeover-products__button ${ day.spaceAvailable ? '' : "disabled"}`}
									onClick={ () => addToCart( day, 1, day.id, true ) }>
										{ day.spaceAvailable ? "Book Now" : "Sold Out" }
								</button>
							</div>
						</div>
					)
				})}
			</div>
		</section>
	)
}